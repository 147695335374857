import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getUsers() {
        return HttpWrapper.get(`${endpoints.USERACCOUNTS}`);
    },
    getUserByUniqueId(uniqueId) {
        return HttpWrapper.get(`${endpoints.USERACCOUNT}/${uniqueId}`);
    },
    setTemporaryPassword(emailAddress, newPassword) {
        return HttpWrapper.put(`${endpoints.USERACCOUNT}/resetPasswordByEmail`, { EmailAddress: emailAddress, Password: newPassword});
    },
    forcePasswordUpdatesOnLogin(emailAddresses) {
        return HttpWrapper.post(`${endpoints.USERACCOUNT}/forceChangePasswordsByEmail`, emailAddresses);
    },
    forcePasswordUpdateOnLogin(emailAddress) {
        return HttpWrapper.put(`${endpoints.USERACCOUNT}/forceChangePasswordByEmail/${emailAddress}`);
    },
    addTitleUser(userAccount) {
        return HttpWrapper.post(`${endpoints.USERACCOUNT}/addTitleUser`, userAccount);
    },
    updateTitleUser(userAccount) {
        return HttpWrapper.put(`${endpoints.USERACCOUNT}/updateTitleUser`, userAccount);
    },
    updateTitleUsers(userAccounts) {
        return HttpWrapper.post(`${endpoints.USERACCOUNT}/updateTitleUsers`, userAccounts);
    },
    enableUser(userAccount) {
        userAccount.status = 1;
        return HttpWrapper.put(`${endpoints.USERACCOUNT}/updateTitleUser`, userAccount);
    },
    disableUser(userAccount) {
        userAccount.status = 0;
        return HttpWrapper.put(`${endpoints.USERACCOUNT}/updateTitleUser`, userAccount);
    },
    saveAvatar(uniqueId, image) {
        return HttpWrapper.put(`${endpoints.USERACCOUNT}/${uniqueId}/Avatar`, image, { headers: { 'Content-Type': 'multipart/form-data' } } );
    },
    removeAvatar(uniqueId) {
        return HttpWrapper.put(`${endpoints.USERACCOUNT}/${uniqueId}/Avatar`, null, { headers: { 'Content-Type': 'multipart/form-data' } } );
    },
    getAvatar(uniqueId) {
        return HttpWrapper.get(`${endpoints.USERACCOUNT}/${uniqueId}/Avatar`);
    },
    saveCurrentOrder(uniqueId,orderUniqueId) {
        return HttpWrapper.put(`${endpoints.USERACCOUNT}/${uniqueId}/SetCurrentOrder/${orderUniqueId}`);
    },
    updateNotificationPreference(uniqueId, payload) {
        var simplePayload = _.pick(payload, ['notificationEmail', 'htmlEmail', 'notificationSms', 'cellPhone','mfaPhoneNumber', 'address1', 'address2', 'city', 'state', 'zip', 'forwardAddress1', 'forwardAddress2', 'forwardCity', 'forwardState', 'forwardZip'] );
        return HttpWrapper.put(`${endpoints.USERACCOUNT}/${uniqueId}/NotificationPreference`, simplePayload);
    },
    getUserAccountByUniqueIdWithPermissions(uniqueId) {
        return HttpWrapper.get(`${endpoints.USERACCOUNT}/UniqueId/${uniqueId}/getWithPermissions`);
    },
    getUserAccountWithPermissions(uniqueId) {
        return HttpWrapper.get(`${endpoints.USERACCOUNT}/getWithPermissions/${uniqueId}`);
    },
    // getEsignCredentials(uniqueId) {
    //     return HttpWrapper.get(`${endpoints.USERACCOUNT}/${uniqueId}/Esign`);
    // },
    // updateEsignCredentials(uniqueId, esign) {
    //     return HttpWrapper.put(`${endpoints.USERACCOUNT}/${uniqueId}/Esign`, esign);
    // },
    getActiveAdminUsersCount() {
        return HttpWrapper.get(`${endpoints.USERACCOUNT}/getActiveAdminUsersCount`);
    },
    deleteUsersWithPermissions(uniqueIds) {
        return HttpWrapper.post(`${endpoints.USERACCOUNT}/deleteUsersWithPermissions`, uniqueIds);
    },
    deleteWithPermissions(uniqueId) {
        return HttpWrapper.delete(`${endpoints.USERACCOUNT}/deleteWithPermissions/${uniqueId}`);
    },
    addWithPermissions(user) {
        return HttpWrapper.post(`${endpoints.USERACCOUNT}/addWithPermissions`, user);
    },
    updateWithPermissions(user, siteAdmin, internalAdmin, fileAdmin) {
        return HttpWrapper.post(`${endpoints.USERACCOUNT}/updateWithPermissions/${siteAdmin}/${internalAdmin}/${fileAdmin}`, user);
    },
    mfaEnable(uniqueId, phoneNumber) {
        return HttpWrapper.put(`${endpoints.USERACCOUNT}/${uniqueId}/MFAEnable`, { UserAccountId: 0, PhoneNumber: phoneNumber } );
    },
    generateMfaCode(userAccountId, phoneNumber, notificationSms) {
        return HttpWrapper.post(`${endpoints.USERACCOUNT}/MFAGenerate`, { UserAccountId: userAccountId, PhoneNumber: phoneNumber, NotificationSms: notificationSms } );
    },
}