import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getUsersByRoleName(roleName,searchUser) {
        if (searchUser) {
            return HttpWrapper.get(`${endpoints.ROLE}/${roleName}/Users/${searchUser}`);
        }
        else
        {
            return HttpWrapper.get(`${endpoints.ROLE}/${roleName}/Users`);
        }
    },
    //TitleUserDto - UNI-1833
    getTitleUsers() {
        return HttpWrapper.get(`${endpoints.ROLE}/Title/Users`);
    }
}