import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getTitleCompanies() {
        return HttpWrapper.get(`${endpoints.TITLECOMPANY}`);
    },
    getTitleCompany(companyId) {
        companyId = companyId || 0;
        return HttpWrapper.get(`${endpoints.TITLECOMPANY}/${companyId}`);
    },
    createTitleCompany(newTitleCompany) {
        return HttpWrapper.post(`${endpoints.TITLECOMPANY}`, newTitleCompany);
    },
    updateTitleCompany(data) {
        return HttpWrapper.put(`${endpoints.TITLECOMPANY}/${data.companyInfo.id}`, data);
    },
    getLogo(id) {
        return HttpWrapper.get(`${endpoints.TITLECOMPANY}/${id}/Logo`);
    }
}