import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    addImage(data) {
        return HttpWrapper.post(`${endpoints.ESCROWBANK}/AddWiringInstructionsImage`, data);
    },
    getAll() {
        return HttpWrapper.get(`${endpoints.ESCROWBANK}`);
    },
    getById(id) {
        return HttpWrapper.get(`${endpoints.ESCROWBANK}/${id}`);
    },
    updateInstructions(id, instructions) {
        return HttpWrapper.put(`${endpoints.ESCROWBANK}/${id}/UpdateInstructions`, { id: id, instructions: instructions });
    },
    update(escrowBank) {
        return HttpWrapper.put(`${endpoints.ESCROWBANK}`, escrowBank);
    },
    uploadVideo(recordId, imageDto) {
        return HttpWrapper.put(`${endpoints.ESCROWBANK}/${recordId}/VideoUpload`, imageDto );
    },
    getVideo(recordId) {
        return HttpWrapper.get(`${endpoints.ESCROWBANK}/${recordId}/GetVideo`);
    }
}