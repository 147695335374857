import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    // getSmktData() {
    //     return HttpWrapper.get(`${endpoints.CONSUMER}/GetSmktData`);
    // },
    getConsumer(email, setLastLogin) {
        return HttpWrapper.get(`${endpoints.CONSUMER}/${email}/${setLastLogin}`);
    },
    getConsumerOrdersByUserAccountUniqueId(userUniqueId) {
        return HttpWrapper.get(`${endpoints.CONSUMER}/${userUniqueId}/Orders`);
    },
    getConsumerOrderByUniqueId(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.CONSUMER}/${orderUniqueId}/Order`);
    },
    getDocumentsByOrderUniqueId(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.CONSUMER}/${orderUniqueId}/Documents`);
    },
    getWiringDocumentByUniqueId(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.CONSUMER}/${orderUniqueId}/WiringInstructions`, { 'responseType': 'blob' } );
    },
    insertWiringInstructionsNote(orderId, userAccountId) {
        return HttpWrapper.put(`${endpoints.CONSUMER}/InsertWiringInstructionsNote/${orderId}/${userAccountId}`);
    }
}