import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getApiBuildInfo() {
        return HttpWrapper.get(`${endpoints.HEALTH}/BuildInfo`);
    },

    getApiHealth(verbose = false) {
        let action = verbose ? "/verbose" : "";
        return HttpWrapper.get(`${endpoints.HEALTH}${action}`);
    },

    getUiHealth() {
        return HttpWrapper.get(`${location.origin}/health.json`);
    },
}