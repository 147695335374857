import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getDashboardByOrder(orderUniqueId, startTime, endTime) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Dashboard/${startTime}/${endTime}`);
    },
    getDashboardActivityContent(activityType, contentId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${activityType}/${contentId}/DashboardContent`);
    }
}