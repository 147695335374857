import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getAll() {
        return HttpWrapper.get(`${endpoints.COMMUNICATIONTEMPLATE}`);
    },
    getById(id) {
        return HttpWrapper.get(`${endpoints.COMMUNICATIONTEMPLATE}/${id}`);
    },
    update(id, template) {
        return HttpWrapper.put(`${endpoints.COMMUNICATIONTEMPLATE}/${id}`, template);
    },
    addImage(id, data) {
        return HttpWrapper.post(`${endpoints.COMMUNICATIONTEMPLATE}/${id}/AddImage`, data);
    },
    saveRoles(id, roles) {
        return HttpWrapper.put(`${endpoints.COMMUNICATIONTEMPLATE}/Roles/${id}`, roles);
    },
}