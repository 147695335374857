import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getAll() {
        return HttpWrapper.get(`${endpoints.CONTACTROLEENTITY}`);
    },
    getAllFormat() {
        return HttpWrapper.get(`${endpoints.CONTACTROLEENTITY}/Format`);
    },
    update(entity) {
        return HttpWrapper.put(`${endpoints.CONTACTROLEENTITY}`, entity);
    },
}
