import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    // saveOverrides(data) {
    //     return HttpWrapper.post(`${endpoints.WIREINSTRUCTIONSORDER}/Overrides`, data);
    // },
    saveOverride(data) {
        return HttpWrapper.post(`${endpoints.WIREINSTRUCTIONSORDER}/Override`, data);
    },
    getByOrderWithOverrides(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/WireInstructions/WithOverrides`);
    },
}