import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    // getDocumentContent(orderUniqueId,documentUniqueId) {
    //     return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Document/${documentUniqueId}/Download`, { 'responseType': 'blob' } );
    // },
    // getDocumentTokenUrl(orderUniqueId,documentUniqueId) {
    //     return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Document/${documentUniqueId}/TokenUrl` );
    // },
    getDocumentTokenUrls(orderUniqueId, documentUniqueIds) {
        return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/Document/TokenUrls`, documentUniqueIds );
    },
    getByOrderUniqueId(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Documents`);
    },
    // getSharedByOrderUniqueId(orderUniqueId) {
    //     return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/DocumentsShared`);
    // },
    getShareableByOrderUniqueId(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/DocumentsShareable`);
    },
    getDocumentAttendees (orderUniqueId, documentUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Document/${documentUniqueId}/Attendees`);
    },
    getContent(orderUniqueId,documentUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Document/${documentUniqueId}/Content`);
    },
    saveSharingPermissions(permissions) {
        return HttpWrapper.post(`${endpoints.ACCOUNTDOCUMENT}/SharingPermissions`, permissions);
    },
    setEsignPackageStatus(documentId, status) {
        return HttpWrapper.put(`${endpoints.DOCUMENT}/${documentId}/SetEsignPackageStatus/${status}`, null);
    },
    saveTags(orderUniqueId, documentUniqueId, tagList) {
        return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/Document/${documentUniqueId}/Tags`, tagList);
    },
    getTags(orderUniqueId,documentUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Document/${documentUniqueId}/Tags`);
    },
    getForDocumentViewer(orderUniqueId, documentUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Document/${documentUniqueId}/Viewer`);
    },
    getAuditReport(orderUniqueId, documentUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Document/${documentUniqueId}/AuditReport`, { 'responseType': 'blob' } );
    },
    uploadDocumentWithUniqueId(orderUniqueId, fileAsFormData) {
        return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/DocumentUpload`, fileAsFormData, { headers: { 'Content-Type': 'multipart/form-data' } } );
    },
    scanAndUploadWithUniqueId(orderUniqueId, fileInfo) {
        return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/ScanAndUpload`, fileInfo, { headers: { 'Content-Type': 'multipart/form-data' } } );
    },
    // scanDocument(orderUniqueId, fileAsFormData) {
    //     return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/DocumentScan`, fileAsFormData, { headers: { 'Content-Type': 'multipart/form-data' } } );
    // },
}