import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getProceedsByOrderId(orderId) {
        return HttpWrapper.get(`${endpoints.SELLERPROCEEDS}/${orderId}`);
    },
    getProceedsByOrderIdAndUserAccountId(orderId, userAccountId) {
        return HttpWrapper.get(`${endpoints.SELLERPROCEEDS}/${orderId}/${userAccountId}`);
    },
    getProceedsDisclosure(orderId, userAccountId) {
        return HttpWrapper.get(`${endpoints.SELLERPROCEEDS}/Disclosure/${orderId}/${userAccountId}`);
    },
    AddSellerProceeds(sellerProceeds) {
        return HttpWrapper.post(`${endpoints.SELLERPROCEEDS}`, sellerProceeds);
    },
    deleteSellerProceeds(sellerProceedsId) {
        return HttpWrapper.delete(`${endpoints.SELLERPROCEEDS}/${sellerProceedsId}`);
    }
}