import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    saveOverrides(data) {
        return HttpWrapper.post(`${endpoints.STAFF}/Overrides`, data);
    },
    saveOverride(data) {
        return HttpWrapper.post(`${endpoints.STAFF}/Override`, data);
    },
    getById(id) {
        return HttpWrapper.get(`${endpoints.STAFF}/${id}`);
    },
    // getByIdWithOverrides(id) {
    //     return HttpWrapper.get(`${endpoints.STAFF}/${id}/WithOverrides`);
    // },
    getByOrder(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Staff`);
    },
    getByOrderWithOverrides(orderUniqueId, showHidden) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Staff/WithOverrides`, { params: { showHidden: showHidden ? showHidden : false} });
    },
    getWithSettlementAgent() {
        return HttpWrapper.get(`${endpoints.STAFFROLES}/WithSettlementAgent`);
    }
}