import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getMilestoneDataByTemplateId(templateId) {
        return HttpWrapper.get(`${endpoints.MILESTONE}/data/${templateId}`);
    },
    getMilestones(templateId, showDisabled) {
        return HttpWrapper.get(`${endpoints.MILESTONE}/ordered/${templateId}/${showDisabled}`);
    },
    // getMilestone(milestoneId) {
    //     return HttpWrapper.get(`${endpoints.MILESTONE}/${milestoneId}`);
    // },
    addMilestone(milestone) {
        return HttpWrapper.post(`${endpoints.MILESTONE}`, milestone);
    },
    updateMilestone(milestone) {
        return HttpWrapper.put(`${endpoints.MILESTONE}`, milestone);
    },
    reorderMilestones(milestones) {
        return HttpWrapper.put(`${endpoints.MILESTONE}/reorder`, milestones);
    },
    syncMilestones(templateTypeId) {
        return HttpWrapper.put(`${endpoints.MILESTONE}/syncMilestones/${templateTypeId}`);
    },
    disableMilestone(milestoneId) {
        return HttpWrapper.put(`${endpoints.MILESTONE}/${milestoneId}/disable`);
    },
    enableMilestone(milestoneId) {
        return HttpWrapper.put(`${endpoints.MILESTONE}/${milestoneId}/enable`);
    },
    deleteMilestone(milestoneId) {
        return HttpWrapper.delete(`${endpoints.MILESTONE}/${milestoneId}`);
    }
}