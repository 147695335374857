import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    addNote(orderUniqueId, note) {
        return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/AddNote`, note);
    },
    // archiveFile(orderUniqueId, archive) {
    //     return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/ArchiveFile/${archive}`);
    // },
    getByUniqueId(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}`);
    },
    getFileOrder(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/FileOrderDetails`);
    },
    getFileOrderByFileNum(fileNum) {
        return HttpWrapper.get(`${endpoints.ORDER}/byFileNum/${fileNum}/FileOrderDetails`);
    },
    getOrderUniqueIdByFileNum(fileNum) {
        return HttpWrapper.get(`${endpoints.ORDER}/byFileNum/${fileNum}`);
    },
    getTopNewOrders(newOnly) {
        return HttpWrapper.get(`${endpoints.ORDER}/Search`, { params: { newOnly: newOnly }});
    },
    esignOptOut(orderUniqueId, userUniqueId) {
        return HttpWrapper.put(`${endpoints.ORDER}/${orderUniqueId}/AccountMapping/${userUniqueId}/ESignOptOut`);
    },
    createEsignCredentials(orderUniqueId, userAccountUniqueId, esign) {
        return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/AccountMapping/${userAccountUniqueId}/Credentials`, esign);
    },
    getEsignCredentialsForOrder(orderUniqueId, userUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/AccountMapping/${userUniqueId}/Credentials`);
    },
    getEsignAccepted(orderUniqueId, userUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/AccountMapping/${userUniqueId}/EsignAccepted`);
    },
    acceptEsignConsent(orderUniqueId, userUniqueId) {
        return HttpWrapper.put(`${endpoints.ORDER}/${orderUniqueId}/AccountMapping/${userUniqueId}/AcceptESignConsent`);
    },
    declineEsignConsent(orderUniqueId, userUniqueId) {
        return HttpWrapper.put(`${endpoints.ORDER}/${orderUniqueId}/AccountMapping/${userUniqueId}/DeclineESignConsent`);
    },
    getEsignAccountMapping(orderUniqueId, userUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/AccountMapping/${userUniqueId}`);
    },
    updateEsignAccountMapping(accountMappings) {
        return HttpWrapper.post(`${endpoints.ESIGN}/Recipients`, accountMappings);
    },
    markComplete(orderUniqueId, documentUniqueId, userUniqueId) {
        return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/Document/${documentUniqueId}/AccountMapping/${userUniqueId}/MarkComplete`);
    },
    finishEsign(orderUniqueId, documentUniqueId, userUniqueId) {
        return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/Document/${documentUniqueId}/AccountMapping/${userUniqueId}/FinishESign`);
    },
    // getId(orderUniqueId) {
    //     return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/GetId`)
    // },
    // getEconsentLog(orderUniqueId) {
    //     return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/eConsentLog`);
    // }
}