import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getAll() {
        return HttpWrapper.get(`${endpoints.COMMUNICATIONTEMPLATETOKEN}`);
    },
    getById(id) {
        return HttpWrapper.get(`${endpoints.COMMUNICATIONTEMPLATETOKEN}/${id}`);
    },
}