import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    saveOverrides(data) {
        return HttpWrapper.post(`${endpoints.CONTACT}/Overrides`, data);
    },
    saveOverride(data) {
        return HttpWrapper.post(`${endpoints.CONTACT}/Override`, data);
    },
    save(contacts) {
        return HttpWrapper.put(`${endpoints.CONTACT}/BulkSave`, contacts);
    },
    getByIdWithOverrides(id) {
        return HttpWrapper.get(`${endpoints.CONTACT}/${id}/WithOverrides`);
    },
    getById(id) {
        return HttpWrapper.get(`${endpoints.CONTACT}/${id}`);
    },
    getByOrder(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Contacts`);
    },
    getByOrderWithOverrides(orderUniqueId, showHidden) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Contacts/WithOverrides`, { params: { showHidden: showHidden ? showHidden : false} });
    },
}