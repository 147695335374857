import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    UploadImage(propertyId, imageDto) {
        return HttpWrapper.put(`${endpoints.PROPERTY}/${propertyId}/Image`, imageDto);
    },
    DeleteImage(propertyId) {
        return HttpWrapper.delete(`${endpoints.PROPERTY}/${propertyId}/RemoveImage`);
    },
    GetByOrderUniqueId(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Property`);
    },
    GetImage(propertyId) {
        return HttpWrapper.get(`${endpoints.PROPERTY}/${propertyId}/GetImage`);
    }
}