
import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getAll() {
        return HttpWrapper.get(`${endpoints.STAFFROLEENTITY}`);
    },
    get(id) {
        return HttpWrapper.get(`${endpoints.STAFFROLEENTITY}/${id}`);
    },
    update(id, entity) {
        return HttpWrapper.put(`${endpoints.STAFFROLEENTITY}/${id}`, entity);
    },
    // insert(entity) {
    //     return HttpWrapper.post(`${endpoints.STAFFROLEENTITY}`, entity)
    // },
}
