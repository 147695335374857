import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getMilestonesByOrder(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/Milestones`);
    },
    getOrderMilestoneParties(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/NotificationParties`);
    },
    getOrderMilestoneNotificationParties(orderUniqueId, milestoneIds) {
        return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/OrderMilestoneNotificationParties`, milestoneIds);
    },
    markMilestoneComplete(orderUniqueId, milestoneId, parties) {
        return HttpWrapper.put(`${endpoints.ORDER}/${orderUniqueId}/Milestone/${milestoneId}/MarkComplete`, parties);
    },
    sendMilestoneCompleteNotification(orderUniqueId, milestoneId, parties) {
        return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/Milestone/${milestoneId}/CompleteNotification`, parties);
    },
    removeCompleteStatus(orderUniqueId, milestoneId) {
        return HttpWrapper.put(`${endpoints.ORDER}/${orderUniqueId}/Milestone/${milestoneId}/MarkIncomplete`);
    },
    saveMilestoneNotificationSettings(orderUniqueId, notificationSettings) {
        return HttpWrapper.post(`${endpoints.ORDER}/${orderUniqueId}/Milestone/NotificationSettings`, notificationSettings);
    }
}