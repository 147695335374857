import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getOrderBuyersAndSellers(orderUniqueId) {
        return HttpWrapper.get(`${endpoints.ORDER}/${orderUniqueId}/BuyerAndSellerOverrides`);
    },
    updateBuyerSeller(buyerSellerOverrides) {
        return HttpWrapper.put(`${endpoints.OVERRIDE}/BuyerAndSeller`, buyerSellerOverrides);
    }
}