import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    // getSmktData() {
    //     return HttpWrapper.get(`${endpoints.LANDINGPAGE}/GetSmktData`);
    // },
    getLandingPage: (setLastLogin=false, token=null) => {
        let cfg = (token && { headers: { Authorization: `Bearer ${token}` } }) || null;
        return HttpWrapper.get(`${endpoints.LANDINGPAGE}/${setLastLogin}`, cfg);
    },
}