import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getLatest() {
        return HttpWrapper.get(`${endpoints.ESIGNCONSENTDISCLOSURE}/Latest`);
    },
    getById(id) {
        return HttpWrapper.get(`${endpoints.ESIGNCONSENTDISCLOSURE}/${id}`);
    }
}