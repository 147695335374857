import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getDocumentTypesWithRoles() {
        return HttpWrapper.get(`${endpoints.DOCUMENTTYPECONTACTROLE}/DocumentTypeWithRoles`);
    },
    saveDocumentTypeWithRoles(documentTypeId, isSignable, documentTypeContactRoles) {
        return HttpWrapper.post(`${endpoints.DOCUMENTTYPECONTACTROLE}/SetAssignedRoles/${documentTypeId}/${isSignable}/${documentTypeContactRoles}`);
    },
}