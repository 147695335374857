import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    get() {
        return HttpWrapper.get(`${endpoints.CONFIGURATION}`);
    },
    update(configuration) {
        return HttpWrapper.put(`${endpoints.CONFIGURATION}`, configuration);
    },
    getAsBoolean(name) {
        return HttpWrapper.get(`${endpoints.CONFIGURATION}/Boolean/${name}`);
    },
}