import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    sendEmailInvite(orderUniqueId, party) {
        return HttpWrapper.post(`${endpoints.INVITATION}/addInvitation/email/${orderUniqueId}`, party);
    },
    sendSmsInvite(orderUniqueId, party) {
        return HttpWrapper.post(`${endpoints.INVITATION}/addInvitation/sms/${orderUniqueId}`, party);
    },
    saveInvitation(party) {
        return HttpWrapper.put(`${endpoints.INVITATION}/saveInvitation`, party);
    },
    revokeInvite(party) {
        return HttpWrapper.put(`${endpoints.INVITATION}/revokeInvitation`, party);
    },
    getParties(currentOrderUniqueId) {
        return HttpWrapper.get(`${endpoints.INVITATION}/getParties/${currentOrderUniqueId}`);
    },
    sendClosingDetails(orderUniqueId, party) {
        return HttpWrapper.post(`${endpoints.INVITATION}/addClosingDetails/${orderUniqueId}`, party);
    }
}