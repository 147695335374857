export const ORDER = '/Order';
export const CONSUMER = '/Consumer';
export const LANDINGPAGE = '/LandingPage';
export const DOCUMENT = '/Document';
export const DOCUMENTTYPECONTACTROLE = '/DocumentTypeContactRole';
export const ROLE = '/Role';
export const USERACCOUNT = '/UserAccount';
export const USERACCOUNTS = '/UserAccounts';
export const USERNOTIFICATIONSETTINGS = '/UserNotificationSettings';
export const INVITATION = '/Invitation';
export const TITLECOMPANY = '/TitleCompany';
export const MILESTONE = '/Milestone';
// export const MILESTONEWORKFLOWTASK = '/MilestoneWorkflowTask';
// export const WORKFLOWTASK = '/WorkflowTask';
export const ORDERMILESTONE = '/OrderMilestone';
// export const WIREINSTRUCTIONSCOMPANY = '/WireInstructionsCompany';
export const ESCROWBANK = '/EscrowBank';
export const CONTACTROLEENTITY = '/ContactRoleEntity';
export const ACCOUNTDOCUMENT = '/AccountDocument';
export const STAFFROLEENTITY = '/StaffRoleEntity';
export const ESIGN = '/ESign';
export const COMMUNICATIONTEMPLATE = '/CommunicationTemplate';
export const COMMUNICATIONTEMPLATETOKEN = '/CommunicationTemplateToken';
export const ESIGNCONSENTDISCLOSURE = '/EsignConsentDisclosure';
export const USERLOGIN = '/UserLogin';
export const CONFIGURATION = '/Configuration';
export const OVERRIDE = '/override';
export const PROPERTY = '/Property';
export const LOCATION = '/Location';
export const CONTACT = '/Contact';
export const STAFF = '/Staff';
export const SELLERPROCEEDS = '/SellerProceeds';
export const STAFFROLES = '/StaffRoles';
export const WIREINSTRUCTIONSORDER = '/WireInstructionsOrder';
export const TEMPLATETYPE = '/TemplateType';
export const HEALTH = '/Health';
export const UTILITY = '/Utility';