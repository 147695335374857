import { HttpWrapper } from '@/shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getByUserAccountId(userAccountId) {
        return HttpWrapper.get(`${endpoints.USERNOTIFICATIONSETTINGS}/UserAccount/${userAccountId}`);
    },
    updateUserNotificationSettings(settings) {
        return HttpWrapper.put(`${endpoints.USERNOTIFICATIONSETTINGS}/Update`, settings);
    },
}